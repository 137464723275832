<!--客户管理-我的设备-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span='24' class='toolbar' style='padding-bottom: 0px;'>
      <el-form :inline='true' :model='filter' size="mini">
        <el-form-item>
          <el-input v-model.trim='filter.name' placeholder='关键字搜索'>
            <el-button slot='prepend' icon='el-icon-refresh' @click='handleRefresh'></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' size='mini' @click='getData'>查询</el-button>
          <el-button type='primary' size='mini' @click='handleAdd'>新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span='24'>
      <el-table :data='datalist.content' size='mini' border highlight-current-row v-loading='loading' @selection-change='selsChange' :max-height='clientHeight' style='width: 100%'>
        <el-table-column type='selection'/>
        <el-table-column type='index' label='序号' align='center' width='55'/>
        <el-table-column prop='typeName' label='监测点类型' align='center' width='200' header-align='center'/>
        <el-table-column prop='name' label='监测点类型名称' align='center' width='200' header-align='center'/>
        <el-table-column prop='permissions' label='运维服务' show-overflow-tooltip align='center' header-align='center'/>
        <el-table-column prop='remark' label='备注' show-overflow-tooltip align='center' header-align='center'/>
        </el-table-column>
        <el-table-column label='操作' align='center' header-align='center' fixed="right" width="140px">
          <template slot-scope='scope'>
            <i title='编辑' @click='handleEdit(scope.$index, scope.row)' class='opt el-icon-edit'/>
            <i title='删除' @click='handleDel(scope.$index, scope.row)' class='opt el-icon-delete'/>
          </template>
        </el-table-column>
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span='24' class='toolbar'>
      <el-button type='danger' size='mini' @click='bulkRemove' :disabled='sels.length===0'>批量删除</el-button>
      <el-pagination small background 
        @size-change='handleSizeChange' 
        @current-change='handleCurrentChange'
        :current-page.sync='filter.page' :page-sizes='filter.pageSizes'
        layout='total, sizes, prev, pager, next, jumper' :total='datalist.total'
        style='display:inline-block;margin-left:15px'
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' width="50%"  top="20px" @close='handlerClose'>
      <div style="overflow-y: auto; height: 70vh;margin-top:20px;">
        <el-form :model='formData' label-width='130px' :rules='formRule' ref='form' size='mini'>
          <el-form-item prop='type' label='监测点类型'>
            <el-select
              placeholder="请选择监测类型"
              style="width: 100%"
              @change="hasChange"
              :disabled="dlg.state == 2"
              v-model="formData.type">
              <el-option :value="1" label="监测类" />
              <el-option :value="2" label="非监测类 "/>
            </el-select>
          </el-form-item>
          <el-form-item prop='name' label='监测点名称'>
            <el-input @change='hasChange' v-model.trim='formData.name'/>
          </el-form-item>
          <el-form-item prop="remark" label="备注">
            <el-input
              type="textarea"
              rows="3"
              @change="hasChange"
              v-model.trim="formData.remark"
            />
          </el-form-item>
          <el-form-item prop="remark" label="运维服务">
            <div v-loading="authGroupLoading" class="group-wrapper">
              <div v-for="v in roleAuthList" :key="v.resource" class="group-list">
                <div class="group-list-title">
                  <i class="el-icon-s-data" />
                  <span>{{ v.resource }}</span>
                </div>
                <div class="group-list-container">
                  <el-checkbox v-for="i in v.permissions" :key="i.id" v-model="i.granted" @change="handleChange($event,i.id)">{{i.display_name}}</el-checkbox>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot='footer' class='dialog-footer'>
        <el-button size='mini' @click.native='dlg.visible=false'>取消</el-button>
        <el-button type='primary' size='mini' @click='handleSubmit()'>提交
        </el-button>
      </div>
    </el-dialog>
   
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {
  join

} from '@/util/index'

export default {
  name: 'LocaleType',
  components: {},
  data() {
    return {
      changeState: false,
      sels: [], 
      loading: false,
      datalist: {},
      permission: [],
      roleAuthList: [],
      dlg: {
        visible: false,
        state: null,
        title: ''
      },
      selDate: null,
      inloading: false,
      filter: {
        page: 1,
        size: 20,
        name: '',
        pageSizes: [20, 30, 50]
      },
      formData: {
        id: 0,
        type: 0,
        name: "",
        remark: "",
        permission_ids: [],
      },
      formRule: {
        name: [{required: true, message: '监测点类型名称不可为空', trigger: 'blur'}],
        type: [{required: true, message: '监测点类型不可为空', trigger: 'blur'}]
      },
      authGroupLoading: false,
    }
  },
  computed: {
    ...mapState(['clientHeight']),
  },
  async mounted() {
    await this.getPermission()
    this.getData()
  },
  methods: {
    handleChange(value, id){
      this.$forceUpdate();
    },
    hasChange() {
      this.changeState = true
    },
    async getPermission() {
      this.permission = []
      this.roleAuthList = []
      this.$get('admin/permission', {resource_type: 1}).then(res => {
        this.permission = res.list
        var permissions = []
        for (var prop in res.list) {
          if (permissions.indexOf(res.list[prop].resource) === -1) {
            permissions.push(res.list[prop].resource)
          }
        }
        var permissionList = []
        for (var i = 0; i < permissions.length; i++) {
          permissionList[i] = {}
          permissionList[i].resource = permissions[i]
          permissionList[i].permissions = []
        }
        for (prop in res.list) {
          for (i = 0; i < permissionList.length; i++) {
            if (res.list[prop].resource === permissionList[i].resource) {
              res.list[prop].granted = false
              permissionList[i].permissions.push(res.list[prop])
            }
          }
        }
        this.roleAuthList = permissionList
      })
    },
    getData () {
      this.loading = true
      const payload = {
        page: this.filter.page,
        size: this.filter.size,
        name: this.filter.name ? this.filter.name : '',
        resource_type: 1
      }
      this.$get('admin/locale_type', payload).then(res => {
        for (var i = 0; i < res.list.length; i++) {
          if (res.list[i].type == 1) {
            res.list[i].typeName = "监测类"
          } else {
            res.list[i].typeName = "非监测类"
          }
          if (res.list[i].permission_ids) {
            var permission = ""
            for (var a = 0; a < res.list[i].permission_ids.length; a++) {
              for (var b = 0; b < this.permission.length; b++) {
                if (res.list[i].permission_ids[a] == this.permission[b].id) {
                  permission  = permission + this.permission[b].display_name + ","
                  break
                }
              }
            }
            res.list[i].permissions = permission.substr(0, permission.length-1);
          } else {
            res.list[i].permissions = "暂未绑定"
          }
        }
        this.datalist.content = res.list
        this.datalist.total = res.count
        this.loading = false
      })
    },
    handleRefresh(clear = true) {
      if (clear) {
        this.filter.page = 1
        this.filter.name = ""
      }
      this.getData()
    },
    selsChange: function (sels) {
      this.sels = sels
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleAdd: function () {
      this.dlg.title = '新增'
      this.dlg.state = 1
      this.dlg.visible = true
      this.formData = {type: 1}
      for (var i = 0; i < this.roleAuthList.length; i++) {
        for (var j = 0; j < this.roleAuthList[i].permissions.length; j++) {
          this.roleAuthList[i].permissions[j].granted = false
        }
      }
    },
    handleEdit: function (index, row) {
      this.dlg.title = '编辑'
      this.dlg.state = 2
      this.dlg.visible = true
      this.$get(`admin/locale_type/${row.id}`, {}).then(res => {
        this.formData = res
        if (!this.authGroupLoading) this.authGroupLoading = true
        for (var i = 0; i < this.roleAuthList.length; i++) {
          for (var j = 0; j < this.roleAuthList[i].permissions.length; j++) {
            this.roleAuthList[i].permissions[j].granted = false
            if (res.permission_ids) {
              var permissionIds = res.permission_ids
              if (permissionIds.indexOf(this.roleAuthList[i].permissions[j].id) > -1) {
                this.roleAuthList[i].permissions[j].granted = true
              }
            }
          }
        }
        this.authGroupLoading = false
      })
    },

    bulkRemove: function () {
      const ids = this.sels.map(item => item.id).toString()
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post(`admin/locale_type/delete/${ids}`, {}).then((res) => {
          this.getData()
        }).catch(() => {

        })
      }).catch(() => {
      })
    },
    handleDel: function (index, row) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post(`admin/locale_type/delete/${row.id}`, {}).then(() => {
          this.datalist.content.splice(index, 1)
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            let rolesData = []
            for (const v of this.roleAuthList) {
              v.permissions
                .filter(i => i.granted)
                .forEach(j => {
                  rolesData.push(j.id)
                })
            }
            rolesData = rolesData.length > 0 ? rolesData.join(',') : ''
            const params = {
              permission_ids: rolesData,
              name: this.formData.name,
              type: this.formData.type,
              remark: this.formData.remark,
              resource_type: 1
            }
            let param = new URLSearchParams()
              Object.entries(params).forEach(([key, val]) => {
                param.append(key, val)
              })

            if (this.dlg.state == 1) {
              this.$post('admin/locale_type/add', param).then(res => {
                this.$message({
                  type: "success",
                  message: '添加成功'
                })
                this.dlg.visible = false
                setTimeout(()=> {
                  this.getData()
                }, 500)
              })
            } else {
              this.$post(`admin/locale_type/edit/${this.formData.id}`, param).then(res => {
                this.$message({
                  type: "success",
                  message: '处理成功'
                })
                this.dlg.visible = false
                setTimeout(()=> {
                  this.getData()
                }, 500)
              })
            }
          }).catch((e) => {
            console.log(e)
          })
        }
      })
    },
    handlerClose: function () {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh(false)
      }
      this.formData = {}
    },
  }
}
</script>
<style lang="scss" scoped>
$height: 590px;
.group-wrapper {
  border: 1px solid #ddd;
  height: $height;
  overflow: hidden;
}

.group-list {
  &:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
  &-title {
    padding: 10px 10px 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px dotted #ddd;
    span {
      color: #666;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    .el-checkbox {
      margin: 15px;
    }
  }
}
.el-icon-download {
  color: #666666;
}

.el-icon-time {
  color: #409EFF;
}
</style>
